<template>
  <div class="edrea_tm_section animated" id="home">
    <div class="section_inner">
      <div class="edrea_tm_home">
        <h3 class="name">MOREIRA <span class="coloring">DEV</span></h3>
        <h3 class="job">
          <AnimationText />
        </h3>
        <!-- <div class="edrea_tm_button transition_link">
          <a href="#contact" @click="navFuntions.activeSection('contact')"
            >Eleve seu potencial</a
          >
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { navFuntions } from "@/navFuntions";
import AnimationText from "./AnimationText.vue";

export default {
  name: `HomeComponent`,
  data() {
    return {
      navFuntions,
    };
  },
  components: { AnimationText },
};
</script>

<style scoped>
.edrea_tm_section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background: linear-gradient(135deg, #ff6347, #ff7f50); */
  color: white;
  animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.section_inner {
  text-align: center;
  max-width: 800px;
  padding: 20px;
  animation: slideIn 1.5s ease-in-out;
}

@keyframes slideIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.name {
  font-size: 4em;
  margin-bottom: 0.5em;
  animation: textFadeIn 2s ease-in-out;
}

@keyframes textFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.coloring {
  /* color: #ff6347; */
  background: white;
  padding: 0 10px;
  border-radius: 5px;
}

.job {
  font-size: 2em;
  margin-bottom: 1.5em;
  animation: textFadeIn 2s ease-in-out 0.5s;
}

.edrea_tm_button {
  margin-top: 20px;
  animation: buttonFadeIn 2s ease-in-out 1s;
}

@keyframes buttonFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.edrea_tm_button a {
  display: inline-block;
  padding: 10px 30px;
  color: white;
  /* background-color: #ff6347; */
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.edrea_tm_button a:hover {
  background-color: #ff7f50;
}
</style>
