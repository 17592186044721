<template>
  <div class="edrea_tm_section" id="about">
    <div class="section_inner">
      <div class="edrea_tm_about">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/1-1.jpg" alt="" />
            <div class="main" data-img-url="https://iili.io/d5cHOXa.png"></div>
          </div>
        </div>
        <div class="right">
          <div class="short">
            <h3 class="name">MOREIRA <span class="coloring">DEV</span></h3>
            <!-- <h3 class="job">
              <AnimationText />
            </h3> -->
          </div>
          <div class="text">
            <p>
              Somos a <span>MOREIRA DEV</span>, uma empresa de desenvolvimento
              de software formada por um grupo de amigos apaixonados por
              tecnologia. Nos dedicamos a criar soluções personalizadas que
              realmente façam a diferença. Combinamos anos de experiência e uma
              grande diversidade de habilidades técnicas para garantir que cada
              projeto seja um sucesso.
            </p>
          </div>
          <div style="display:none" class="edrea_tm_button" id="aboutPopup">
            <a href="#">Saber mais</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimationText from "./AnimationText.vue";
export default {
  name: `AboutComponent`,
  data() {
    return {
      open: false,
    };
  },
  components: {
    AnimationText,
  },
};
</script>

<style scoped>
.edrea_tm_modalbox {
  top: -90px;
}


.coloring {
  /* color: #ff6347; */
  background: white;
  padding: 0 10px;
  border-radius: 5px;
}


</style>
