<template>
  <span class="cd-headline rotate-1">
    <span class="blc"></span>
    <span class="cd-words-wrapper">
      <b
        :class="text == i ? 'is-visible' : 'is-hidden'"
        v-for="(skill, i) in skills"
        :key="i"
        class="skill-item"
      >
        <div class="icon-wrapper">
          <img :src="skill.icon" class="skill-icon" v-if="skill.icon" />
        </div>
      </b>
    </span>
  </span>
</template>

<script>
export default {
  name: `AnimationText`,
  data() {
    return {
      skills: [
        { name: "C#", icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg" },
        { name: "Python", icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg" },
        { name: "JavaScript", icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" },
        { name: "MySQL", icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg" },
        { name: "ReactJS", icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" },
        { name: "HTML", icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" },
        { name: "Dart", icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/dart/dart-original.svg" },
        { name: "Mobile Development", icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/android/android-original.svg" },
        { name: "Flutter", icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg" },
        { name: "SQL Server", icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/microsoftsqlserver/microsoftsqlserver-plain.svg" },
        { name: "C++", icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg" }
      ],
      text: 0,
    };
  },
  mounted() {
    const interval = setInterval(() => {
      this.text = this.text < this.skills.length - 1 ? this.text + 1 : 0;
    }, 3000);
    return () => clearInterval(interval);
  },
};
</script>

<style scoped>
.cd-headline {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
}

.cd-words-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

.skill-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px; /* Ajuste conforme necessário */
}

.is-visible {
  display: flex;
}

.is-hidden {
  display: none;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.skill-icon {
  width: 50px; /* Ajuste o tamanho conforme necessário */
  height: 50px; /* Ajuste o tamanho conforme necessário */
}
</style>
