<template>
  <div class="edrea_tm_section" id="equipe">
    <div class="section_inner">
      <div class="edrea_tm_portfolio swiper-section">
        <div class="edrea_tm_main_title">
          <h3>Nossa <span class="coloring">Equipe</span></h3>
        </div>
        <div class="portfolio_list">
          <div class="team_member" v-for="member in teamMembers" :key="member.name">
            <div class="member_content">
              <h3>{{ member.name }}</h3>
              <p class="role">{{ member.role }}</p>
              <p class="experience">{{ member.experience }}</p>
              <ul class="additional_info">
                <li v-for="info in member.additionalInfo" :key="info">{{ info }}</li>
              </ul>
              <a v-if="member.linkedin" :href="member.linkedin" target="_blank" class="linkedin_link">
                <font-awesome-icon :icon="['fab', 'linkedin']" /> LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortfolioComponent",
  data() {
    return {
      teamMembers: [
        {
          name: "Fellipe Sales",
          role: "Engenheiro de Software",
          experience: "Experiência: 15 anos",
          additionalInfo: [            
            "Desenvolvedor full stack"
          ],
          linkedin: "https://www.linkedin.com/in/fellipesales/"
        },        
        {
          name: "Matheus Ragoso",
          role: "Analista de segurança",
          experience: "Experiência: 14 anos",
          additionalInfo: [
            "Desenvolvedor full stack"
          ],
          linkedin: "https://www.linkedin.com/in/ragoso/"
        },
        {
          name: "Filipe Moreira",
          role: "Desenvolvedor full stack",
          experience: "Experiência: 7 anos",
          additionalInfo: [],
          linkedin: "https://www.linkedin.com/in/filipe-moreira-b62112197/"
        },
        {
          name: "João Dias",
          role: "DevOps",
          experience: "Experiência: 10 anos",
          additionalInfo: [],
          linkedin: "https://www.linkedin.com/in/jo%C3%A3o-matheus-cardoso-dias-29636220a/"
        },
        {
          name: "Daniel Sanches",
          role: "Desenvolvedor full stack",
          experience: "Experiência: 3 anos",
          additionalInfo: [],
          linkedin: "https://www.linkedin.com/in/daniel-pereira-sanches-0a1ba0210/"
        },
        {
          name: "José Luiz",
          role: "Desenvolvedor Mobile",
          experience: "Experiência: 3 anos",
          additionalInfo: [],
          linkedin: "https://www.linkedin.com/in/jos%C3%A9-luiz-santos-silva-48a7951b4/"
        },
        {
          name: "Isac Martins",
          role: "Desenvolvedor full stack",
          experience: "Experiência: 13 anos",
          additionalInfo: [],
          linkedin: "" // Link não fornecido
        },
      ]
    };
  }
};
</script>

<style scoped>
.edrea_tm_section {
  padding: 50px 0;
}


.coloring {
  /* color: #ff6347; */
  background: white;
  padding: 0 10px;
  border-radius: 5px;
}

.section_inner {
  max-width: 1200px;
  margin: 0 auto;
}

.edrea_tm_main_title {
  text-align: center;
  margin-bottom: 30px;
}

.edrea_tm_main_title h3 {
  font-size: 2.5em;
  font-weight: bold;
  color: #ffffff;
}

.coloring {
  color: #ff5722;
}

.portfolio_list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.team_member {
  flex: 1 1 calc(33.333% - 20px);
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.team_member:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.member_content h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #ffffff;
}

.role, .experience {
  font-size: 1.1em;
  color: #dddddd;
  margin-bottom: 10px;
}

.additional_info {
  list-style: none;
  padding: 0;
}

.additional_info li {
  font-size: 1em;
  color: #bbbbbb;
}

.linkedin_link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: #0e76a8;
  text-decoration: none;
}

.linkedin_link:hover {
  text-decoration: underline;
}

.linkedin_link svg {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .team_member {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .team_member {
    flex: 1 1 100%;
  }
}
</style>
